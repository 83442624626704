import { autoinject } from 'aurelia-framework';
import { Redirect } from 'aurelia-router';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import AuthService from 'services/auth-service';
import { ErrorService } from 'services/error-service';

@autoinject
export class ForgotPassword {
  errorMessage: string;
  validationController: ValidationController;
  validationRules: any;

  dto: {
    email: string;
  };

  emailSent: boolean = false;
  isLoading: boolean = null;
  fromMigration: boolean = false;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController =
      validationControllerFactory.createForCurrentScope();
  }

  async canActivate() {
    if (localStorage.getItem('aboIdUser:loggedIn')) {
      return new Redirect('/');
    }
  }

  activate({ email, fromMigration }) {
    this.dto = {
      email: email ? decodeURIComponent(email) : null,
    };

    if (fromMigration === 'grannar') {
      this.fromMigration = true;
    }

    this.setupValidation();
  }

  async setupValidation() {
    ValidationRules.ensure((it: any) => it.email)
      .required()
      .withMessage('E-post er påkrevd')
      .email()
      .withMessage('Ugyldig e-postadresse')
      .on(this.dto);
  }

  async resetPassword() {
    this.errorMessage = null;

    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.isLoading = true;

    try {
      await this.authService.getResetPasswordLink(this.dto.email);
      this.isLoading = false;
      this.emailSent = true;
    } catch (error) {
      this.isLoading = false;
      if (!error.response) {
        this.errorMessage =
          'aboID er ikke tilgjengelig akkurat nå. Prøv igjen senere.';
      } else if (error?.response?.data?.message) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }
}
