import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ProfileRes } from 'models/api';
import UserService from 'services/user-service';
import { ErrorService } from 'services/error-service';

@autoinject
export class AcceptTerms {
  private profile: ProfileRes;
  private loading: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private errorService: ErrorService
  ) {}

  async activate() {
    this.getData();
  }

  async getData() {
    this.profile = await this.userService.getUser();

    // Navigate to home page if already acceptet
    if (this.profile.acceptedTerms) {
      this.router.navigateToRoute('home');
    }
  }

  async acceptTerms() {
    this.loading = true;
    try {
      await this.userService.acceptTerms();
      this.router.navigateToRoute('home');
      this.loading = false;
    } catch (error) {
      this.errorService.handleError(error);
      this.loading = false;
    }
  }
}
