import { Router, Redirect } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import {
  ProfileRes,
  UserSubscription,
  UserUserGroup,
  UserUserGroupInvitation,
} from 'models/api';
import AuthService from 'services/auth-service';
import SubscriptionService from 'services/subscription-service';
import UserService from 'services/user-service';
import { ErrorService } from 'services/error-service';
import { isBefore } from 'date-fns';
import { DialogService } from 'aurelia-dialog';
import { CreateGroupModal } from 'components/group/create-group-modal';
import { NotificationsService } from 'services/notifications-service';

@autoinject
export class Home {
  private profile: ProfileRes;
  private subscriptions: UserSubscription[];
  private activeSubscriptions: object[] = [];

  private loading: boolean = true;

  subscriptionErrors: any[];

  groups: UserUserGroup[];
  groupInvitations: UserUserGroupInvitation[];

  networkError: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private notificationsService: NotificationsService
  ) {}

  async activate() {
    this.getData();
  }

  async getData() {
    this.loading = true;

    try {
      this.activeSubscriptions = [];

      this.profile = await this.userService.getUser();
      this.groups = await this.userService.getGroups();
      this.groupInvitations = await this.userService.getGroupInvitations();
      this.subscriptions =
        await this.subscriptionService.getUserSubscriptions();
      this.loading = false;

      this.subscriptions.forEach((subscription) => {
        if (subscription.status === 'active') {
          this.activeSubscriptions.push(subscription);
        } else if (subscription.status === 'cancelled') {
          if (
            subscription.expireDate &&
            isBefore(new Date(), new Date(subscription.expireDate))
          ) {
            this.activeSubscriptions.push(subscription);
          }
        }
      });

      // Subscriptions with Nets errors
      this.subscriptionErrors = this.subscriptions
        .filter((it) => it?.billingAgreement?.hasNetsError)
        .map((it) => {
          return {
            id: it.id,
            message: `Betalingskortet på abonnementet ${it.product.name} er ikke gyldig. Oppdater betalingsinfo for å fortsatt bruke abonnementet.`,
          };
        });
    } catch (error) {
      if (!error?.response) {
        console.error(error);
        this.activeSubscriptions = null;
        this.networkError = true;
        this.notificationsService.error(
          'Ikke tilgjengelig',
          'aboID er ikke tilgjengelig akkurat nå. Prøv igjen senere.'
        );
      } else {
        this.errorService.handleError(error);
      }
      this.loading = false;
    }

    // Navigate to terms page
    if (!this.profile.acceptedTerms) {
      this.router.navigateToRoute('accept-terms');
    }
  }

  createGroup() {
    this.dialogService
      .open({
        viewModel: CreateGroupModal,
        model: {
          subscriptions: this.subscriptions
            .filter((it) => it.status === 'active')
            .filter((it) => it.product.digital),
          groups: this.groups,
        },
        keyboard: true,
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.getData();
        }

        if (res?.output) {
          this.router.navigateToRoute('group-details', { id: res.output });
        }
      });
  }

  async respondToInvitation(
    invitation: UserUserGroupInvitation,
    accept: boolean
  ) {
    try {
      await this.userService.respondGroupInivtation(invitation.id, { accept });
      if (accept === true) {
        this.notificationsService.success(
          'Invitasjon godtatt',
          'Du er nå medlem av gruppen'
        );
        this.router.navigateToRoute('group-details', {
          id: invitation.userGroup.id,
        });
      } else {
        this.notificationsService.success(
          'Invitasjon avslått',
          'Invitasjonen har blitt avslått'
        );
        this.getData();
      }
    } catch (error) {
      this.errorService.handleError(error);
      this.getData();
    }
  }
}
