import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EditGroupModal } from 'components/group/edit-group-modal';
import { GroupAddMemberModal } from 'components/group/group-add-member-modal';
import { ProfileRes, User, UserPendingGroupInvitation, UserUserGroup } from 'models/api';
import { ErrorService } from 'services/error-service';
import { ModalService } from 'services/modal-service';
import { NotificationsService } from 'services/notifications-service';
import SubscriptionService from 'services/subscription-service';
import UserService from 'services/user-service';

interface Params {
  id: number;
}

@autoinject
export class GroupDetails {
  groupId: number;
  group: UserUserGroup;
  user: ProfileRes;
  isAdmin: boolean = false;
  pendingInvitations: UserPendingGroupInvitation[];

  constructor(
    private subscriptionService: SubscriptionService,
    private userService: UserService,
    private modalService: ModalService,
    private router: Router,
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService
  ) {}

  async activate({ id }: Params) {
    this.groupId = id;
    this.getGroup();
  }

  async getGroup() {
    try {
      const { group, currentUserIsAdmin } = await this.userService.getGroup(this.groupId);
      this.group = group;
      this.isAdmin = currentUserIsAdmin;

      if (currentUserIsAdmin) {
        this.pendingInvitations = await this.userService.getGroupPendingInvitations(this.groupId);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        this.router.navigateToRoute('home');
        this.notificationsService.error('Gruppe ikke funnet', 'Gruppen finnes ikke');
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  async deleteGroup() {
    const confirmRemoval = await this.modalService.confirmDanger({
      title: 'Slett gruppe',
      body: `Er du sikker på at du vil slette gruppen ${this.group.name} og fjerne delt tilgang til ${this.group.usersCount} medlemmer?`,
      confirmButtonText: 'Slett gruppe',
    });

    if (!confirmRemoval) {
      return;
    }

    try {
      await this.userService.deleteGroup(this.groupId);
      this.notificationsService.success('Gruppe slettet', 'Gruppen er nå slettet');
      this.router.navigateToRoute('home');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  addMember() {
    this.dialogService
      .open({
        viewModel: GroupAddMemberModal,
        model: {
          group: this.group,
        },
        keyboard: true,
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.getGroup();
        }
      });
  }

  removeMember(userId: number) {
    try {
      this.userService.removeGroupMember(this.groupId, userId);
      this.notificationsService.success('Medlem fjernet', 'Medlemmet er nå fjernet fra gruppen');
      setTimeout(() => {
        this.getGroup();
      }, 500);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  deleteInvitation(invitationId: number) {
    try {
      this.userService.deleteGroupInvitation(invitationId);
      this.notificationsService.success('Invitasjon fjernet', 'Invitasjonen er nå fjernet');
      setTimeout(() => {
        this.getGroup();
      }, 500);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  editGroup() {
    this.dialogService
      .open({
        viewModel: EditGroupModal,
        model: {
          group: this.group,
        },
        keyboard: true,
      })
      .whenClosed((res) => {
        this.getGroup();
      });
  }
}
